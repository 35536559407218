@import '../../styles/dimensions';

.isRtl nav.navbar-investor {
  font-family: 'Open Sans Hebrew Condensed', 'Open Sans Hebrew', 'Open Sans';
  .container-fluid {
    .nav-tab {
      .navbar-drop-entry {
        .dropdown {
          &-toggle {
            div.navbar-userName {
              float: right;
              margin-right: 5px;
            }
          }
          &-toggle::before {
            float: right;
          }
          &.open {
            .dropdown-menu {
              left: -6px;
              .header-icon::before {
                content: none;
              }
            }
          }
        }
      }
    }
    .nav-tab-container {
      float: right;
      .nav-tab {
        float: right;
        a.navbar-entry::before {
          content: none;
        }
      }
    }
    .nav-tab-buttonWidth {
      &-logo {
        float: right;
        padding-right: 0;
      }
    }
    .nav-tab-userActionMenu {
      float: left;
    }

    .header-mobile-nav {
      float: right;
      transform: scaleX(-1);
    }
  }

  .mobile-menu-wrapper {
    left: auto;
    right: -100%;
    float: left;
    &.open {
      left: auto;
      right: 0;
    }
    .mobile-menu {
      .menu-header {
        float: right;
        .menu-close {
          float: left;
          height: 35px;
          width: 35px;
          margin: 10px 0 0 5px;
        }
      }
      .menu-items {
        float: right;
        .menu-item {
          a {
            font-size: 25px;
            padding-left: auto;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

nav.navbar-investor {
  padding-left: calc(100vw - 100%);
  //font-family: 'Open Sans Hebrew Condensed', 'Open Sans Hebrew', 'Open Sans';
  background: #262726;
  border-radius: 0;
  border: 0;
  color: #ffffff;
  margin-bottom: 0;
  position: fixed;
  z-index: 15;
  width: 100%;
  .container-fluid {
    .nav-tab-userActionMenu {
      float: right;
      .active {
        .dropdown {
          color: rgba(54, 183, 234, 1);
          .dropdown-toggle {
            &::before,
            &::after {
              background-image: url('/images/icons_icon_user_on.png');
            }
          }
        }
      }
    }
    .maxWidthTab {
      max-width: 200px;
      @media (max-width: 767px) {
        max-width: 60px;
      }
    }
    .nav-tab {
      padding-left: 0;
      padding-right: 0;
      letter-spacing: 0.15;
      color: rgba(229, 229, 229, 1);
      font-size: 15px;
      .navbar-entry {
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-transform: capitalize;
        padding: 15px 0px;
        text-align: center;
        height: 51px;
        display: block;
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        letter-spacing: 0.02em;
      }
      .navbar-drop-entry:hover {
        .dropdown {
          .dropdown-toggle {
            &::before,
            &::after {
              background-image: url('/images/icons_icon_user_on.png');
            }
          }
        }
      }
      .navbar-drop-entry {
        @extend .navbar-entry;
        padding: 15px 0;
        text-transform: none;
        .dropdown {
          position: static;
          &.open {
            .dropdown {
              &-toggle {
                &::before {
                  background-image: url('/images/icons_icon_user_on.png');
                }
                @media (max-width: 480px) {
                  &::before {
                    background-image: url('/images/icons_icon_more_on.png');
                  }
                }
                span.custom-caret {
                  @extend .custom-caret;
                  background-image: url('/images/arrow_up.png') !important;
                }
              }
              &-menu {
                left: -22px;
                min-width: 185px;
                background-color: #262726;
                top: 75%;
                z-index: inherit;
                border-radius: 0;
                border: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                padding: 0;
                .header-icon {
                  text-decoration: none;
                }
                li > a {
                  color: #fff;
                  padding: 10px 30px;
                  cursor: pointer;
                  border-bottom: 1px solid #353535;
                  &.active,
                  &:hover {
                    background-color: #515251;
                  }
                }
              }
            }
          }

          &-toggle::before {
            background-image: url('/images/icons_icon_user_off.png');
            content: '';
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            float: left;
            margin-top: 1px;
          }
          &-toggle {
            display: inline-block;
            div.navbar-userName {
              text-overflow: ellipsis;
              max-width: 94px;
              overflow: hidden;
              white-space: nowrap;
              float: left;
              margin-left: 5px;
            }
            .custom-caret {
              background-image: url('/images/arrow_down.png');
              margin-top: 7px;
              display: inline-block;
              float: none;
              position: relative;
              margin-left: 5px;
              height: 10px;
              width: 10px;
              background-repeat: no-repeat;
            }
          }
          &-toggle::after {
            display: none;
          }
        }
      }
      a.navbar-entry:hover {
        background-color: rgba(255, 255, 255, 0.15);
        color: #fff;
      }
      a.active.navbar-entry {
        background-color: #32a7de;
        color: #fff;
      }
      a.navbar-entry[disabled],
      a.navbar-entry:hover[disabled] {
        opacity: 0.3;
        color: rgba(229, 229, 229, 1);
        background: none;
        cursor: default;
        pointer-events: none;
        &::before,
        &::after {
          background-image: url('/images/icons_icon_dashboard_off.png');
        }
      }
    }
    .nav-tab-buttonWidth {
      &-logo {
        padding-right: 0;
        @media (max-width: $screen-sm) {
          display: none;
        }
      }
    }

    .header-content {
      @media (max-width: $screen-sm) {
        display: none;
      }
    }

    .header-mobile-nav {
      @media (min-width: $screen-sm) {
        display: none;
      }
      height: 51px;
      width: 50px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('/images/icons_icon_mobile_menu.png');
      float: left;
      position: absolute;
      z-index: 2;
    }

    .mobile-titles {
      @media (min-width: $screen-sm) {
        display: none;
      }
      position: absolute;
      top: 0;
      width: 100%;
      .title {
        display: none;
        text-align: center;
        text-decoration: none;
        line-height: 51px;
        color: #fff;
        font-size: 18px;
        letter-spacing: 0.02em;
        &.active {
          display: block;
        }
      }
    }

    .logo {
      margin-left: 0;
      text-align: center;
      width: 100%;
      padding: 0px;
      height: 51px;
      .header-logo {
        height: 100%;
        display: inline;
      }
    }
  }

  .mobile-menu-wrapper {
    position: absolute;
    top: 0;
    left: -100%;
    transition: 0.5s;
    z-index: 3;
    width: 100%;
    height: 100vh;

    &.open {
      left: 0;
    }

    .mobile-menu {
      width: 60%;
      height: 100vh;
      background-color: #25afef;
      .menu-header {
        height: 77px;
        width: 100%;
        float: left;

        .menu-close {
          float: right;
          height: 35px;
          width: 35px;
          margin: 10px 5px 0 0;
          background-image: url('/images/icons_icon_mobilemenu_close.png');
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .menu-items {
        float: left;
        width: 100%;
        font-weight: 300;

        .menu-item {
          height: 53px;
          width: 100%;

          a {
            color: #fff;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 37px;
            padding-left: 20px;
            width: 100%;
            float: left;

            &.active {
              background: rgba(245, 245, 245, 0.15);
            }
          }
        }
      }
    }
  }
  .header-notification {
    float: right;
    margin-right: 2%;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .dropdown-backdrop {
    display: none;
  }
}
