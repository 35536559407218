@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(//fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
.isRtl nav.navbar-investor {
  font-family: 'Open Sans Hebrew Condensed', 'Open Sans Hebrew', 'Open Sans'; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle div.navbar-userName {
    float: right;
    margin-right: 5px; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle::before {
    float: right; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu {
    left: -6px; }
    .isRtl nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu .header-icon::before {
      content: none; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab-container {
    float: right; }
    .isRtl nav.navbar-investor .container-fluid .nav-tab-container .nav-tab {
      float: right; }
      .isRtl nav.navbar-investor .container-fluid .nav-tab-container .nav-tab a.navbar-entry::before, .isRtl nav.navbar-investor .container-fluid .nav-tab-container .nav-tab a.navbar-drop-entry::before {
        content: none; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab-buttonWidth-logo {
    float: right;
    padding-right: 0; }
  .isRtl nav.navbar-investor .container-fluid .nav-tab-userActionMenu {
    float: left; }
  .isRtl nav.navbar-investor .container-fluid .header-mobile-nav {
    float: right;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }
  .isRtl nav.navbar-investor .mobile-menu-wrapper {
    left: auto;
    right: -100%;
    float: left; }
    .isRtl nav.navbar-investor .mobile-menu-wrapper.open {
      left: auto;
      right: 0; }
    .isRtl nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-header {
      float: right; }
      .isRtl nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-header .menu-close {
        float: left;
        height: 35px;
        width: 35px;
        margin: 10px 0 0 5px; }
    .isRtl nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items {
      float: right; }
      .isRtl nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items .menu-item a {
        font-size: 25px;
        padding-left: auto;
        padding-right: 20px; }

nav.navbar-investor {
  padding-left: calc(100vw - 100%);
  background: #262726;
  border-radius: 0;
  border: 0;
  color: #ffffff;
  margin-bottom: 0;
  position: fixed;
  z-index: 15;
  width: 100%; }
  nav.navbar-investor .container-fluid .nav-tab-userActionMenu {
    float: right; }
    nav.navbar-investor .container-fluid .nav-tab-userActionMenu .active .dropdown {
      color: #36b7ea; }
      nav.navbar-investor .container-fluid .nav-tab-userActionMenu .active .dropdown .dropdown-toggle::before, nav.navbar-investor .container-fluid .nav-tab-userActionMenu .active .dropdown .dropdown-toggle::after {
        background-image: url("/images/icons_icon_user_on.png"); }
  nav.navbar-investor .container-fluid .maxWidthTab {
    max-width: 200px; }
    @media (max-width: 767px) {
      nav.navbar-investor .container-fluid .maxWidthTab {
        max-width: 60px; } }
  nav.navbar-investor .container-fluid .nav-tab {
    padding-left: 0;
    padding-right: 0;
    letter-spacing: 0.15;
    color: #e5e5e5;
    font-size: 15px; }
    nav.navbar-investor .container-fluid .nav-tab .navbar-entry, nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry {
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
      padding: 15px 0px;
      text-align: center;
      height: 51px;
      display: block;
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      letter-spacing: 0.02em; }
    nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry:hover .dropdown .dropdown-toggle::before, nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry:hover .dropdown .dropdown-toggle::after {
      background-image: url("/images/icons_icon_user_on.png"); }
    nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry {
      padding: 15px 0;
      text-transform: none; }
      nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown {
        position: static; }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-toggle::before {
          background-image: url("/images/icons_icon_user_on.png"); }
        @media (max-width: 480px) {
          nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-toggle::before {
            background-image: url("/images/icons_icon_more_on.png"); } }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-toggle span.custom-caret {
          background-image: url("/images/arrow_up.png") !important; }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu {
          left: -22px;
          min-width: 185px;
          background-color: #262726;
          top: 75%;
          z-index: inherit;
          border-radius: 0;
          border: none;
          box-shadow: none;
          padding: 0; }
          nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu .header-icon {
            text-decoration: none; }
          nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu li > a {
            color: #fff;
            padding: 10px 30px;
            cursor: pointer;
            border-bottom: 1px solid #353535; }
            nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu li > a.active, nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-menu li > a:hover {
              background-color: #515251; }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle::before {
          background-image: url("/images/icons_icon_user_off.png");
          content: '';
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          float: left;
          margin-top: 1px; }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle {
          display: inline-block; }
          nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle div.navbar-userName {
            text-overflow: ellipsis;
            max-width: 94px;
            overflow: hidden;
            white-space: nowrap;
            float: left;
            margin-left: 5px; }
          nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle .custom-caret, nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown.open .dropdown-toggle span.custom-caret {
            background-image: url("/images/arrow_down.png");
            margin-top: 7px;
            display: inline-block;
            float: none;
            position: relative;
            margin-left: 5px;
            height: 10px;
            width: 10px;
            background-repeat: no-repeat; }
        nav.navbar-investor .container-fluid .nav-tab .navbar-drop-entry .dropdown-toggle::after {
          display: none; }
    nav.navbar-investor .container-fluid .nav-tab a.navbar-entry:hover, nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry:hover {
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff; }
    nav.navbar-investor .container-fluid .nav-tab a.active.navbar-entry, nav.navbar-investor .container-fluid .nav-tab a.active.navbar-drop-entry {
      background-color: #32a7de;
      color: #fff; }
    nav.navbar-investor .container-fluid .nav-tab a.navbar-entry[disabled], nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry[disabled],
    nav.navbar-investor .container-fluid .nav-tab a.navbar-entry:hover[disabled],
    nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry:hover[disabled] {
      opacity: 0.3;
      color: #e5e5e5;
      background: none;
      cursor: default;
      pointer-events: none; }
      nav.navbar-investor .container-fluid .nav-tab a.navbar-entry[disabled]::before, nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry[disabled]::before, nav.navbar-investor .container-fluid .nav-tab a.navbar-entry[disabled]::after, nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry[disabled]::after,
      nav.navbar-investor .container-fluid .nav-tab a.navbar-entry:hover[disabled]::before,
      nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry:hover[disabled]::before,
      nav.navbar-investor .container-fluid .nav-tab a.navbar-entry:hover[disabled]::after,
      nav.navbar-investor .container-fluid .nav-tab a.navbar-drop-entry:hover[disabled]::after {
        background-image: url("/images/icons_icon_dashboard_off.png"); }
  nav.navbar-investor .container-fluid .nav-tab-buttonWidth-logo {
    padding-right: 0; }
    @media (max-width: 768px) {
      nav.navbar-investor .container-fluid .nav-tab-buttonWidth-logo {
        display: none; } }
  @media (max-width: 768px) {
    nav.navbar-investor .container-fluid .header-content {
      display: none; } }
  nav.navbar-investor .container-fluid .header-mobile-nav {
    height: 51px;
    width: 50px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/images/icons_icon_mobile_menu.png");
    float: left;
    position: absolute;
    z-index: 2; }
    @media (min-width: 768px) {
      nav.navbar-investor .container-fluid .header-mobile-nav {
        display: none; } }
  nav.navbar-investor .container-fluid .mobile-titles {
    position: absolute;
    top: 0;
    width: 100%; }
    @media (min-width: 768px) {
      nav.navbar-investor .container-fluid .mobile-titles {
        display: none; } }
    nav.navbar-investor .container-fluid .mobile-titles .title {
      display: none;
      text-align: center;
      text-decoration: none;
      line-height: 51px;
      color: #fff;
      font-size: 18px;
      letter-spacing: 0.02em; }
      nav.navbar-investor .container-fluid .mobile-titles .title.active {
        display: block; }
  nav.navbar-investor .container-fluid .logo {
    margin-left: 0;
    text-align: center;
    width: 100%;
    padding: 0px;
    height: 51px; }
    nav.navbar-investor .container-fluid .logo .header-logo {
      height: 100%;
      display: inline; }
  nav.navbar-investor .mobile-menu-wrapper {
    position: absolute;
    top: 0;
    left: -100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 3;
    width: 100%;
    height: 100vh; }
    nav.navbar-investor .mobile-menu-wrapper.open {
      left: 0; }
    nav.navbar-investor .mobile-menu-wrapper .mobile-menu {
      width: 60%;
      height: 100vh;
      background-color: #25afef; }
      nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-header {
        height: 77px;
        width: 100%;
        float: left; }
        nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-header .menu-close {
          float: right;
          height: 35px;
          width: 35px;
          margin: 10px 5px 0 0;
          background-image: url("/images/icons_icon_mobilemenu_close.png");
          background-repeat: no-repeat;
          background-position: center; }
      nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items {
        float: left;
        width: 100%;
        font-weight: 300; }
        nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items .menu-item {
          height: 53px;
          width: 100%; }
          nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items .menu-item a {
            color: #fff;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 37px;
            padding-left: 20px;
            width: 100%;
            float: left; }
            nav.navbar-investor .mobile-menu-wrapper .mobile-menu .menu-items .menu-item a.active {
              background: rgba(245, 245, 245, 0.15); }
  nav.navbar-investor .header-notification {
    float: right;
    margin-right: 2%; }

@media only screen and (max-width: 767px) {
  .dropdown-backdrop {
    display: none; } }

body {
  height: 100%;
  font-family: 'Roboto', 'Open Sans', sans-serif !important; }

html:lang(he) body {
  font-family: 'Open Sans Hebrew', 'Open Sans', sans-serif !important; }

:root {
  height: 100%; }

div a:hover {
  color: rgba(17, 138, 202, 0.7); }

div a,
div a:visited,
div a:active {
  text-decoration: underline;
  color: #118aca; }

button.submit-button,
button.submit-button:active {
  cursor: pointer;
  background-color: #3cbc8b;
  color: white; }

button.submit-button:hover {
  background-color: rgba(60, 188, 139, 0.7);
  color: white; }

button.margin-right {
  margin-right: 10px; }

form .btn-default[disabled]:hover,
form button[disabled]:focus,
.submit-button[disabled] {
  background-color: #bababa;
  color: white;
  cursor: auto !important; }

form button[disabled] {
  background-color: #bababa; }

form .form-control {
  padding: 6px 6px 6px 6px;
  height: 41px; }

div.with-errors {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #ff3f2b;
  font-size: 12px; }
  div.with-errors .error-message {
    padding-left: 18px;
    list-style-type: none; }

input.invalidInput {
  border: 1px solid #ff3f2b; }

.imitateLink {
  text-decoration: underline;
  color: #337ab7; }

.imitateLink[disabled] {
  cursor: none; }

.imitateLink:hover {
  cursor: pointer; }

.imitateLink:visited {
  color: #609; }

.background-logo {
  background-image: url("/images/watermark.png");
  background-repeat: no-repeat;
  background-position: center center; }

@media (max-width: 480px) {
  .custom-hidden-xs {
    display: none !important; } }

@media (max-width: 900px) {
  .image-container {
    background-size: contain; } }

.clearfix:after {
  content: ' ';
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.lightGrayTheme {
  background-color: #f2f3f2; }

.mainPageContainer {
  padding-top: 50px; }

.isRtl .col-lg-offset-4 {
  margin-right: 16.66666667%; }

.isRtl .col-md-offset-2 {
  margin-right: 8.33333333%; }

.isRtl .col-sm-offset-2 {
  margin-right: 8.33333333%; }

.isRtl .col-xs-offset-0 {
  margin-right: 0; }

.isRtl .dropdown-menu {
  text-align: right; }

div.bodyHeight {
  height: 100%; }

a.dropdown-item {
  text-decoration: none; }

.component-container {
  background: white;
  overflow: auto; }

.component-container > div {
  padding: 0 30px; }

.dropdown-toggle::after {
  margin-top: 10%;
  display: inline-block;
  width: 0;
  height: 0;
  left: 5px;
  float: right;
  vertical-align: middle;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

header {
  font-weight: 300;
  font-size: 32px; }

.col-lg-23,
.bodyHeight {
  padding: 0px; }

div.body {
  height: 100%; }

div[class*='col-'].no-gutters {
  padding-right: 0;
  padding-left: 0; }

div[class*='col-'].no-gutter-left {
  padding-left: 0; }

div[class*='col-'].no-gutter-right {
  padding-right: 0; }

@media (max-width: 767px) {
  div[class*='col-'].no-gutters-xs {
    padding-right: 0;
    padding-left: 0; }
  div[class*='col-'].no-gutter-left-xs {
    padding-left: 0; }
  div[class*='col-'].no-gutter-right-xs {
    padding-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  div[class*='col-'].no-gutters-sm {
    padding-right: 0;
    padding-left: 0; }
  div[class*='col-'].no-gutter-left-sm {
    padding-left: 0; }
  div[class*='col-'].no-gutter-right-sm {
    padding-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  div[class*='col-'].no-gutters-md {
    padding-right: 0;
    padding-left: 0; }
  div[class*='col-'].no-gutter-left-md {
    padding-left: 0; }
  div[class*='col-'].no-gutter-right-md {
    padding-right: 0; } }

@media (min-width: 1200px) {
  div[class*='col-'].no-gutters-lg {
    padding-right: 0;
    padding-left: 0; }
  div[class*='col-'].no-gutter-left-lg {
    padding-left: 0; }
  div[class*='col-'].no-gutter-right-lg {
    padding-right: 0; } }

