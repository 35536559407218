@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(//fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

//@font-face {
//  font-family: 'hebrewFont';
//  src: url('%PUBLIC_URL%/fonts/OpenSansHebrewCondensed-Regular.woff') format('woff'),
//  url('%PUBLIC_URL%/fonts/OpenSansHebrewCondensed-Regular.ttf') format('truetype');
//}

body {
  height: 100%;
  font-family: 'Roboto', 'Open Sans', sans-serif !important;
}

html:lang(he) body {
  font-family: 'Open Sans Hebrew', 'Open Sans', sans-serif !important;
}

:root {
  height: 100%;
}

// to bypass bootstrap text-decoration: none;
div {
  a:hover {
    color: rgba(17, 138, 202, 0.7);
  }
  a,
  a:visited,
  a:active {
    text-decoration: underline;
    color: #118aca;
  }
}

button.submit-button,
button.submit-button:active {
  cursor: pointer;
  background-color: rgba(60, 188, 139, 1);
  color: white;
}

button.submit-button:hover {
  background-color: rgba(60, 188, 139, 0.7);
  color: white;
}

button.margin-right {
  margin-right: 10px;
}

form .btn-default[disabled]:hover,
form button[disabled]:focus,
.submit-button[disabled] {
  background-color: #bababa;
  color: white;
  cursor: auto !important;
}

form button[disabled] {
  background-color: rgba(186, 186, 186, 1);
}

form .form-control {
  padding: 6px 6px 6px 6px;
  height: 41px;
}

// Error input label

div.with-errors {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #ff3f2b;
  font-size: 12px;
  .error-message {
    padding-left: 18px;
    list-style-type: none;
  }
}

input.invalidInput {
  border: 1px solid rgba(255, 63, 43, 1);
}

.imitateLink {
  text-decoration: underline;
  color: #337ab7;
}

.imitateLink[disabled] {
  cursor: none;
}

.imitateLink:hover {
  cursor: pointer;
}

.imitateLink:visited {
  color: #609;
}

.background-logo {
  background-image: url('/images/watermark.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-hidden-xs {
  @media (max-width: 480px) {
    display: none !important;
  }
}

.image-container {
  @media (max-width: 900px) {
    background-size: contain;
  }
}

.clearfix:after {
  content: ' '; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.lightGrayTheme {
  background-color: #f2f3f2;
}

.mainPageContainer {
  padding-top: 50px;
}

.isRtl {
  .col-lg-offset-4 {
    margin-right: 16.66666667%;
  }
  .col-md-offset-2 {
    margin-right: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-right: 8.33333333%;
  }
  .col-xs-offset-0 {
    margin-right: 0;
  }

  .dropdown-menu {
    text-align: right;
  }
}

div.bodyHeight {
  height: 100%;
}

a.dropdown-item {
  text-decoration: none;
}

.component-container {
  background: rgba(255, 255, 255, 1);
  overflow: auto;
}

.component-container > div {
  padding: 0 30px;
}

.dropdown-toggle::after {
  margin-top: 10%;
  display: inline-block;
  width: 0;
  height: 0;
  left: 5px;
  float: right;
  vertical-align: middle;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

header {
  font-weight: 300;
  font-size: 32px;
}

.col-lg-23,
.bodyHeight {
  padding: 0px;
}
