@import '../../styles/dimensions';
@import '../../styles/global';

div.body {
  height: 100%;
}

div[class*='col-'] {
  &.no-gutters {
    padding-right: 0;
    padding-left: 0;
  }
  &.no-gutter-left {
    padding-left: 0;
  }
  &.no-gutter-right {
    padding-right: 0;
  }
  @media (max-width: $screen-xs-max) {
    &.no-gutters-xs {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-left-xs {
      padding-left: 0;
    }
    &.no-gutter-right-xs {
      padding-right: 0;
    }
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    &.no-gutters-sm {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-left-sm {
      padding-left: 0;
    }
    &.no-gutter-right-sm {
      padding-right: 0;
    }
  }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    &.no-gutters-md {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-left-md {
      padding-left: 0;
    }
    &.no-gutter-right-md {
      padding-right: 0;
    }
  }
  @media (min-width: $screen-lg-min) {
    &.no-gutters-lg {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-left-lg {
      padding-left: 0;
    }
    &.no-gutter-right-lg {
      padding-right: 0;
    }
  }
}
